<template>
  <b10-base>
    <b10-autocomplete
      v-if="isInsert"
      v-model="form.idmaccion"
      :items="formattedMacciones"
      item-value="maccion.idmaccion"
      item-text="title"
      label="Tipo de acción"
      clearable
      :rules="formRules.idmaccion"
      @change="maccionSelected(form.idmaccion)"
    />
    <v-checkbox
      v-if="!realizada && hasPermModificarMaterialFacturable"
      v-model="form.facturable"
      label="Es facturable"
    />
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './AccionEjecFormData'
import { ACCION_EJEC, MACCION, PARAMETRO } from '@/utils/consts'
import { currentDate, toDate } from '@/utils/date'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    id: {
      type: [Number, String],
      required: false,
      default: '',
    },
    ffinGarantia: {
      type: Date,
      default: function () { return new Date() },
    },
    idparteTrabajoMatsist: {
      type: [Number, String],
      required: true,
    },
    hasPermModificarMaterialFacturable: {
      type: Boolean,
      default: false,
    },
    facturaJuntoRevisionYRetimbrado: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      realizada: false,
      form: {
        id: null,
        idmaccion: null,
        observaciones: '',
        facturable: false
      },
      formRules: {
        idmaccion: [
          v => !!v || 'Campo requerido'
        ],
      },
      macciones: [],
      idsMaccionesExistentes: [],
      sustitucionNoFacturableEnGarantia: false,
      maccionesExistentes: {}
    }
  },
  computed: {
    formattedMacciones () {
      const items = []
      for (let item of this.macciones) {
        // quitar acciones que ya tiene el material afectado
        if (this.idsMaccionesExistentes.indexOf(item.maccion.idmaccion) === -1) {
          item.title = `${item.maccion.descripcion} (${item.maccion.descripcion_corta})`
          items.push(item)
        }
      }
      return items
    },
    accionRealizada () {
      return !this.isInsert && this.realizada
    },
  },
  async created () {
    if (this.isInsert) {
      // no se permite modificar el tipo de acción (solo al insertar)
      this.sustitucionNoFacturableEnGarantia = (
        await this.$offline.parametro.valorParametro(
          PARAMETRO.parametros.SUSTITUCION_NO_FACTURABLE_EN_GARANTIA)
      ) === '1'
      this.macciones = await Data.selectMaccion(this)
      this.maccionesExistentes = await Data.selectAccionesMaterial(this, this.idparteTrabajoMatsist)
      this.idsMaccionesExistentes = _.map(this.maccionesExistentes, 'maccion.idmaccion')
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'id', id)
      this.$set(this.form, 'idmaccion', formData.idmaccion)
      this.$set(this.form, 'observaciones', formData.observaciones)
      this.$set(this.form, 'facturable', formData.facturable)
      this.realizada = formData.idest_accion_ejec === ACCION_EJEC.estados.realizada
    },
    maccionSelected (idmaccion) {
      // por defecto -> facturable
      // si es sustitución y la sustitución en garantía no es facturable (parámetro) y esta en garantia -> no facturable
      // otro caso -> facturable de maccion
      if (idmaccion) {
        const maccionSeleccionada = _.filter(this.macciones, (item) => {
          return (item.maccion.idmaccion === idmaccion)
        })
        this.$set(this.form, 'facturable', maccionSeleccionada[0].maccion.facturable)
        if (
          maccionSeleccionada[0].maccion.clasificacion === MACCION.clasificacion.sustitucion &&
          this.sustitucionNoFacturableEnGarantia &&
          this.ffinGarantia &&
          toDate(this.ffinGarantia) >= currentDate()
        ) {
          this.$set(this.form, 'facturable', false)
        // Si es una revisión y el comportamiento de la subfamilia indica que se facure junto revisión y retimbrado
        // Entonces tengo que ver si ya hay acción de retimbre facturable, si es así la añado como no facturable a la rev.
        } else if (
          this.facturaJuntoRevisionYRetimbrado &&
          (
            maccionSeleccionada[0].maccion.clasificacion === MACCION.clasificacion.revision_anual ||
            maccionSeleccionada[0].maccion.clasificacion === MACCION.clasificacion.revision_semestral ||
            maccionSeleccionada[0].maccion.clasificacion === MACCION.clasificacion.revision_trimestral
          )
        ) {
          const accionRetimbrado = _.find(this.maccionesExistentes, (item) => {
            return (item.maccion.clasificacion === MACCION.clasificacion.retimbrado)
          })
          if (accionRetimbrado && accionRetimbrado.accion_ejec.facturable){
            this.$set(this.form, 'facturable', !accionRetimbrado.accion_ejec.facturable)
          }
        }
      } else {
        this.$set(this.form, 'facturable', true)
      }
    },
  }
}
</script>
