import { ACCION_EJEC } from '@/utils/consts'

export default {
  selectMaccion (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.maccion.select({
      where: tables.maccion.estado.gt(0),
      orderBy: [tables.maccion.orden]
    })
  },
  selectAccionesMaterial (Vue, idparteTrabajoMatsist) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.accion_ejec,
        tables.parte_trabajo_matsist.idorden_trabajo_matsist.eq(tables.accion_ejec.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.maccion,
        tables.accion_ejec.idmaccion.eq(tables.maccion.idmaccion)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo_matsist.eq(idparteTrabajoMatsist),
          tables.maccion.estado.gt(0),
          tables.accion_ejec.idest_accion_ejec.in([
            ACCION_EJEC.estados.pendiente, ACCION_EJEC.estados.realizada
          ]),
        )
      )
      .exec()
  },
  selectAccionEjec (Vue, idaccion_ejec) {
    const tables = Vue.$offline.db.tables
    const accionEjec = Vue.$offline.accionEjec
    return Vue.$offline.db
      .select()
      .from(tables.accion_ejec)
      .innerJoin(accionEjec.dummyTable, accionEjec.pk.eq(accionEjec.dummyPk))
      .where(tables.accion_ejec.idaccion_ejec.eq(idaccion_ejec))
      .exec()
  },
  async selectFormData (Vue, id) {
    return (await Vue.$offline.accionEjec.row(id)).accion_ejec
  },
}
